<template>
  <div class="historia">
	  <div class="container position-relative" :class="{'fade-in' : animate}">
		  	<div class="row mb-5 pb-5">
				<div class="col-md-10 col-7 position-relative d-flex flex-row">
					<router-link to="/">
						<img class="logo me-5 mb-2" src="@/assets/img/logo-aniversario.svg">
					</router-link>
					<h1>Nuestra Historia</h1>
				</div>
				<div class="col-md-2 d-none d-md-block col-5 position-relative text-end">
					<router-link to="quiz">
						<h3 class="quiz">Hacer el Quiz</h3>
					</router-link>
				</div>
		  	</div>
			<div class="row">
				<div class="col-md-5">
					<div class="image-border">
						<transition name="slide-fade" mode="out-in">
							<img :key="current_year" class="year-image" :src="content[current_year].image" />
						</transition>
					</div>
					<transition appear name="slide-fade" mode="out-in">
						<h3 :key="current_year" class="year-year">{{ content[current_year].year }}</h3>
					</transition>
				</div>
				<transition name="slide-fade" mode="out-in">
					<div :key="current_year" class="col-md-7 pt-md-5 pt-3 d-flex flex-column justify-content-center">
							<h2 class="year-title">{{ content[current_year].title }}</h2>
							<p class="year-description">{{ content[current_year].description }}</p>
					</div>
				</transition>
			</div>
			<transition v-bind:name="isotipo_1_transition" mode="out-in">
				<div v-on:click="nextYear()" v-if="current_year < 11" :key="current_year" class="text-center year-plus-one">
					<img class="small-isotipo" src="@/assets/img/isotipo-small.svg">
					<transition name="slide-fade" mode="out-in">
						<h6 :key="current_year">{{ parseInt(content[current_year + 1].year) ? parseInt(content[current_year + 1].year) : content[current_year + 1].year }}</h6>
					</transition>
				</div>
			</transition>
			<transition v-bind:name="isotipo_2_transition" mode="out-in">
				<div v-on:click="nextYear(), nextYear()" :key="current_year"  v-if="current_year < 10" class="text-center year-plus-two">
					<img class="small-isotipo" src="@/assets/img/isotipo-small.svg">
					<transition name="slide-fade" mode="out-in">
						<h6 :key="current_year">{{ parseInt(content[current_year + 2].year) ? parseInt(content[current_year + 2].year) : content[current_year + 2].year }}</h6>
					</transition>
				</div>
			</transition>
			<div class="control position-absolute">
				<button v-if="current_year > 0" v-on:click="prevYear()"><img class="arrow" src="@/assets/img/right-arrow.svg"></button>
				<router-link class="d-block d-md-none" to="/quiz">
					<h3 class="quiz-mobile">Hacer el Quiz</h3>
				</router-link>
				<button v-if="current_year < 11" v-on:click="nextYear()"><img class="arrow" src="@/assets/img/right-arrow.svg"></button>
			</div>
	  </div>
    
  </div>
</template>

<script>
export default {
  	name: 'Historia',

	data() {
		return {
			isotipo_1_transition: '',
			isotipo_2_transition: '',
			current_year: 0,
			content: [
				{
					year: '1996',
					title: 'El nacimiento de Logifruit',
					description: 'Nace Logifruit y comienza su actividad como operador logístico para dar servicio a los proveedores de frutas y verduras de Mercadona.',
					image: require('../assets/img/historia/1996.png')
				},
				{
					year: '1997',
					title: 'Las primeras aperturas',
					description: 'Trasladamos la actividad a Ribarroja del Túria. Además, introducimos cuatro modelos de envases rígidos de plástico, el 6414, el 6418, el 6412 y el 3416.  Abrimos delegaciones en Albatera, Antequera y Getafe. Nos incorporamos a la Asociación Nacional de Fabricantes y Distribuidores (AECOC).',
					image: require('../assets/img/historia/1997.jpg')
				},
				{
					year: '2000',
					title: 'Consolidación de un modelo logístico ',
					description: 'Logifruit realiza 26 millones de movimientos de envases y la plantilla ya alcanza las 125 personas. La cartera de clientes se ha ampliado en los últimos años a proveedores de carne, charcutería, huevos y pescado entre otros. También se abren más plataformas, como Tenerife, Palma de Mallorca y Tarrasa.',
					image: require('../assets/img/historia/2000.jpg')
				},
				{
					year: '2005',
					title: 'Innovando en producto',
					description: 'Lanzamiento del primer modelo de caja plegable. Logifruit introduce al mismo tiempo el palet de plástico en la distribución alimentaria en España, un palet lavable, reutilizable, reciclable y óptimo para la cadena de suministro de la distribución.',
					image: require('../assets/img/historia/2005.jpg')
				},
				{
					year: '2008',
					title: 'Crecimiento sostenible y sostenido',
					description: 'Inauguración de nuevas instalaciones en Villadangos. El modelo de caja plegable sale al mercado. Logifruit alcanza los 174 millones de movimientos de envases.',
					image: require('../assets/img/historia/2008.png')
				},
				{
					year: '2011',
					title: 'Compromiso con los valores humanos',
					description: 'Adhesión a los ODS del Pacto Mundial de la ONU para la sostenibilidad empresarial del sector privado, impulsando la estrategia de RSC de la compañía.',
					image: require('../assets/img/historia/2011.jpg')
				},
				{
					year: '2016',
					title: 'Aniversario de un modelo circular',
					description: 'Logifruit cumple veinte años de un modelo de Calidad Total sustentado en el crecimiento sostenible y abanderando la economía circular como el presente y el futuro de la compañía.',
					image: require('../assets/img/historia/2016.jpg')
				},
				{
					year: '2017',
					title: 'Vitoria: Plataforma 100% automatizada',
					description: 'Inauguración de nuevas instalaciones en Vitoria para dar cobertura a la zona norte peninsular con una cartera de clientes de 846. La plantilla supera los 1.000 colaboradores.',
					image: require('../assets/img/historia/2017.png')
				},
				{
					year: '2018',
					title: 'Logifruit: Sostenible e inclusiva',
					description: 'Firma del nuevo Convenio Colectivo y del segundo Plan de Igualdad. Puesta en marcha del Código Ético y de Conducta. En su apuesta por la sostenibilidad Logifruit promueve el cambio de la totalidad de cajas rígidas a plegables. Se alcanzan los 293 millones de movimientos de envases.',
					image: require('../assets/img/historia/2018.png')
				},
				{
					year: '2019',
					title: 'Innovación en el ADN',
					description: 'Logifruit afianza su posición como empresa innovadora siendo reconocida por la London Stock Exchange como una de las empresas europeas con más potencial de crecimiento dentro de la iniciativa: “1000 companies to inspire Europe”.',
					image: require('../assets/img/historia/2019.jpg')
				},
				{
					year: '2020',
					title: 'Proyección internacional',
					description: 'Logifruit inició su actividad en Portugal, concretamente en una nueva plataforma situada en Povoa do Varzin (Oporto), con el objetivo de acompañar a sus clientes en todo lo que necesiten.',
					image: require('../assets/img/historia/2020.jpg')
				},
				{
					year: 'Hoy',
					title: 'I+D+i con retorno social',
					description: 'La compañía sigue realizando fuertes inversiones que le han permitido afrontar la automatización de procesos productivos y consolidarse como un pool de referencia, con un claro retorno sostenible.',
					image: require('../assets/img/historia/Hoy.jpg')
				}
			],
			animate:true
		}
	},

	created() {
		window.addEventListener('keydown', (e) => {
			if (e.keyCode == 37) {
				this.prevYear()
			}

			if (e.keyCode == 39) {
				this.nextYear()
			}
		});
	},

	updated() {
		this.animate=false;
	},

	methods: {
		
		nextYear() {
			if (this.current_year < this.content.length) {
				this.current_year += 1
			}
			this.calculateTransitionIsotipeOne('next')
			this.calculateTransitionIsotipeTwo('next')
		},

		prevYear() {
			if (this.current_year > 0) {
				this.current_year -= 1
			}
			this.calculateTransitionIsotipeOne('prev')
			this.calculateTransitionIsotipeTwo('prev')
		},

		calculateTransitionIsotipeOne(movement) {
			if (movement == 'next') {
				this.isotipo_1_transition = 'slide1-next'
			}

			if (movement == 'prev') {
				this.isotipo_1_transition = 'slide1-prev'
			}
		},

		calculateTransitionIsotipeTwo(movement) {
			if (movement == 'next') {
				this.isotipo_2_transition = 'slide2-next'
			}

			if (movement == 'prev') {
				this.isotipo_2_transition = 'slide2-prev'
			}
		}
	}


}
</script>

<style scoped>

	body {
		background-color: red;
	}
	.historia {
		position: relative;
		text-align: left;
		background-image: url(../assets/img/story-bg.jpg);
		background-size: cover;
		color: #fff;
		display: flex;
		align-items: center;
		min-height: 100vh;
		width: 100%;
	}

	.historia::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-image: url(../assets/img/isotipo.svg);
		background-repeat: no-repeat;
		background-size: 10%;
		background-position: center center;  
		animation: 1.5s ease-out 1.5s 1 isotipoAnimate;
		-webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
		-moz-animation-fill-mode: forwards;    /* FF 5+ */
		-o-animation-fill-mode: forwards;      /* Not implemented yet */
		-ms-animation-fill-mode: forwards;     /* IE 10+ */
		animation-fill-mode: forwards;         /* When the spec is finished */
	}

	@keyframes isotipoAnimate {
		0% {
			background-size: 10%;
			background-position-x: 40%;
			background-position-y: 40%;
		}
		100% {
			background-size: 430vh;
			background-position-x: 40%;
			background-position-y: calc((50vh - 220px));
		}
	}


	.historia * {
		z-index: 1;
	}

	.logo {
		width: 120px
	}

	.fade-in {
		opacity: 0;
		-webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
		-moz-animation: fadein 2s; /* Firefox < 16 */
			-ms-animation: fadein 2s; /* Internet Explorer */
			-o-animation: fadein 2s; /* Opera < 12.1 */
				animation: fadein 2s;
		animation-delay: 3s;
		-webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
		-moz-animation-fill-mode: forwards;    /* FF 5+ */
		-o-animation-fill-mode: forwards;      /* Not implemented yet */
		-ms-animation-fill-mode: forwards;     /* IE 10+ */
		animation-fill-mode: forwards;         /* When the spec is finished */
	}

	@keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Firefox < 16 */
	@-moz-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Safari, Chrome and Opera > 12.1 */
	@-webkit-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Internet Explorer */
	@-ms-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	/* Opera < 12.1 */
	@-o-keyframes fadein {
		from { opacity: 0; }
		to   { opacity: 1; }
	}

	h1 {
		font-size: 60px;
		font-weight: bold;
		color: #fff
	}

	a {
		text-decoration: none;
	}

	.quiz {
		color: #fff;
		font-weight: bold;
		text-decoration: none;
		margin-top: 20px;
	}

	.quiz-mobile {
		color: #26A361;
		margin-top: 10px;
		text-align: center;
	}

	.image-border {
		display: block;
		max-width: 390px;
		max-height: 390px;
		background-color: #fff;
		padding: 20px;
		border-radius: 500px;
		z-index: 2;

	}

	.year-image {
		border-radius: 500px;
		width: 350px;
		height: 350px;
		object-fit: cover;
		object-position: center;
	}

	.year-year {
		font-size: 60px;
		font-weight: bold;
		color: #26A361;
		margin-left: 105px;
	}

	@media (max-width: 768px) {
		.year-year {
			padding: 7px 18px;
			background-color: #fff;
			border-radius: 30px
		}	
	}

	.year-title {
		display: inline-block;
		width: fit-content;
		margin-bottom: 25px;
		padding: 30px 40px 30px 25px;
		border: 4px solid #26A361;
		font-size: 36px;
		font-weight: bold;
		color: #26A361
	
	}

	.year-description {
		font-size: 18px;
		font-weight: 600;
		color: #26A361
	}

	h6 {
		font-size: 26px;
		font-weight: bold;
		color: #26A361;
	}
	
	.control {
		bottom: 0px;
		right: 0
	}

	button {
		background: #26A361;
		border: none;
		padding: 10px;
	}

	button .arrow {
		width: 40px;
	}

	button:first-child {
		margin-right: 30px;
	}

	@media (max-width: 768px) {
		button:first-child {
			margin-right: 0;
		}
	}

	button:first-child .arrow {
		transform: rotate(180deg);
	}

	/** Animation content slides **/
	.slide-fade-enter-active {
		transition: all .75s ease-in;
	}
	.slide-fade-leave-active {
		transition: all .5s ease-out;
	}
	.slide-fade-enter, .slide-fade-leave-to {
		transform: translateX(0px);
		opacity: 0;
	}


	/** Animation small isotipos movement **/
	.year-plus-one, .year-plus-two {
		position: absolute;
	}

	.year-plus-one {
		top: 21%;
		left: 40%;
	}

	.year-plus-two {
		top: 9%;
		left: 78%;
	}
	

	/** First isotipo **/
	.slide1-next-enter-active, .slide1-next-leave-active, .slide1-prev-enter-active, .slide1-prev-leave-active {
		transition: all .75s ease;
		z-index: 0;
	}

	.slide1-next-enter {
		transform: translate(0%, 0%) !important;
	}
	.slide1-next-leave-to {
		transform: translate(-380%, 130%) !important;
	}

	.slide1-prev-enter {
		transform: translate(0%, 0%) !important;
	}
	.slide1-prev-leave-to {
		transform: translate(473%, -54%) !important;
	}

	/** Second isotipo **/
	.slide2-next-enter-active, .slide2-next-leave-active, .slide2-prev-enter-active, .slide2-prev-leave-active {
		transition: all .75s ease;
	}

	.slide2-next-enter {
		transform: translate(0%, 0%) !important;
		opacity: 0;
	}
	.slide2-next-leave-to {
		transform: translate(-473%, 54%) !important;
	}

	.slide2-prev-enter {
		transform: translate(0%, 0%) !important;
		opacity: 0;
	}
	.slide2-prev-leave-to {
		transform: translate(473%, 34%) !important;
	}

	@media (max-width: 768px) {
		.historia {
			min-height: 100%
		}

		.historia::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-image: url(../assets/img/isotipo.svg);
			background-repeat: no-repeat;
			background-size: 10%;
			background-position-x: center;
			background-position-y: center;
			animation: 1.5s ease-out 1.5s 1 isotipoAnimate;
			-webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
			-moz-animation-fill-mode: forwards;    /* FF 5+ */
			-o-animation-fill-mode: forwards;      /* Not implemented yet */
			-ms-animation-fill-mode: forwards;     /* IE 10+ */
			animation-fill-mode: forwards;         /* When the spec is finished */
		}

		@keyframes isotipoAnimate {
			0% {
				background-size: 10%;
				background-position-x: 40%;
				background-position-y: 40%;
			}
			100% {
				background-size: 1100%;
				background-position-x: 40%;
				background-position-y: -5%;
			}
		}

		.container.position-relative {
			min-height: 100%;
			padding: 20px;
			padding-bottom: 90px
		}

		h1 {
			font-size: 40px
		}

		.small-isotipo, h6 {
			display: none;
		}

		.image-border {
			width: 200px;
			height: 200px;
			padding: 10px
		}

		.year-image {
			width: 180px;
			height: 180px;
		}

		.year-year {
			position: absolute;
			right: 20px;
			top: 35%;
			margin: 0;
			font-size: 32px
		}

		.year-title {
			font-size: 26px;
			padding: 15px 20px
		}

		.year-description {
			font-size: 16px;
		}

		.control {
			width: 100%;
			bottom: 20px;
			left: 0;
			padding-left: 20px;
			padding-right: 20px;
			display: flex;
			justify-content: space-between; 
		}

		.logo {
			margin-top: 10px;
			width: 100px
		}
	}	

</style>